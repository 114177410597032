import React from 'react'
import Position from '../../../components/Position';
import Highlight from '../../../components/Highlight';
import { Container, Space, Center, Grid } from '../../../GlobalStyles';
import { StaticImage } from "gatsby-plugin-image";

import useI18n from '../../../i18n/useI18n';

const Description = ({ location }) => {

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'Manage and',
        es: 'Gestiona'
    }    
    const title = {
        en: 'follow the daily tasks of your team',
        es: 'y da seguimiento a las tareas diarias de tu equipo'
    }

    return (
        <Container>
            <Space />
            <Position location={location} />
            <Center>
                <h2 data-sal="slide-up" data-sal-easing="ease-in-circ">
                    <Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}
                </h2>
                <p data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay=".2s">
                    {translateObject({
                        en:`That your team’s tasks are not left undone, with TaskMe you will be able to monitor and be alert when there are tasks that are pending or are delayed. In the same way, each person on your team will be able to follow up on each of their assigned tasks in a simple way.`,
                        es: `Que las tareas de tu equipo no queden sin hacer, con TaskMe podrás monitorear y estar alerta cuando hay tareas pendientes o retrasadas. De la misma forma, cada persona de tu equipo podrá hacer un seguimiento de cada una de las tareas que tiene asignadas de manera sencilla.`
                    })}
                </p>
            </Center>


            <Grid>
                <div className='col-1'data-sal='slide-right' data-sal-easing="ease-in-circ">
                    <h3>
                        {translateObject({
                            en: `No more forgotten tasks`,
                            es: `No más tareas olvidadas`
                        })}
                    	</h3>
                    <p>
                        {translateObject({
                            en: `Each task is configured to have a date and time when it has to be done, 
                            by means of notifications each member of the staff will be alerted when they have a task that has not been done, 
                            in this way there will be no more tasks that are forgotten.`,
                            es: `Cada tarea está configurada para tener una fecha y hora en que se debe realizar, 
                            por medio de notificaciones cada miembro del personal será alertado cuando tenga una tarea que no se ha realizado, 
                            de esta manera no habrá más tareas olvidadas.`
                        })}
                    </p>    
                </div>

                <div className='col-2'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='300'>
                    <StaticImage
                        src="../../../images/taskme-1.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="taskme-1"
                        style={{ width: '100%', height: 'fit-content' }}
                    />
                </div>

                <div className='col-3'data-sal='slide-left' data-sal-easing="ease-in-circ">
                    <h3>
                        {translateObject({
                            en: `Train your all staff easily`,
                            es: `Capacita facilmente a tu personal`
                        })}
                    </h3>
                    <p>
                        {translateObject({
                            en: `Reduce time training your staff and use TaskMe to achieve it. 
                            It includes quizzes with documents, videos and questions that your staff can study as many times as necessary
                             until they have mastered the subject on which they are training.`,
                            es: `Utiliza TaskMe y reduce el tiempo de capacitación de tu personal. 
                            Incluye cuestionarios con documentos, videos y preguntas que tu personal puede estudiar tantas veces como sea necesario 
                            hasta dominar el tema en el que se esté capacitando.`
                        })}
                    </p>
                </div>
            </Grid>
        </Container>
    )
};

export default Description;