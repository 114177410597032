import React from "react";
import Seo from "../components/seo";
import ProductLayout from "../containers/ProductLayout";
import { Section } from '../GlobalStyles.jsx';
import { graphql, useStaticQuery } from "gatsby";
import '../globalStyles.css';
import { BG_DARK, BG } from '@etiquette-ui/colors';
import Description from "../sections/Products/Taskme/description";
import SecondSection from "../sections/Products/Taskme/second-section";


const Taskme = () => {
  const dataquery = useStaticQuery(query);
  const datainfo = dataquery.allFile.edges;

  const data = {
    currentPage: 8,
    title: 'TaskMe',
    heroLogo: datainfo[0].node.childrenImageSharp[0].fluid,
    heroAltLogo: datainfo[0].node.base,
    heroImage: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAlt: datainfo[3].node.base,
    heroMobile: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAltMobile: datainfo[3].node.base,
    banerImg: datainfo[4].node.childrenImageSharp[0].fluid,
    banerAlt: datainfo[4].node.base,
    banerImgMobile: datainfo[4].node.childrenImageSharp[0].fluid,
    banerImgAltMobile: datainfo[4].node.base,
    features: [
      { 
        en: 'Get information from Dotell on staff performance and show it quickly',
        es: 'Obten de forma rápida la información de Dotell sobre el rendimiento del equipo'
      },
      { 
        en: 'Ideal for creating daily or special tasks by managers',
        es: 'Ideal para crear tareas diarias o especiales para gerentes'
      },
      { 
        en: 'Create, edit or manage tasks assigned to employees',
        es: 'Crea, edita o gestiona tareas asignadas a los emplados'
      },
      { 
        en: 'Get detailed information on each of the tasks to be performed',
        es: 'Obtén información detallada cada tarea realizada'
      },
      { 
        en: 'As a manager, monitor and approve the tasks performed by your team',
        es: 'Como gerente puedes monitorear y mejorar las tareas realizadas por tu equipo'
      },
      { 
        en: 'As an employee, track and mark assigned tasks as completed',
        es: 'Como miembro del equipo puedes dar seguimiento y marcar como cumplidas tus tareas asignadas'
      },
      { 
        en: 'As an employee, request an extension of time if a task cannot be performed at the indicated time',
        es: 'Como miembro del equipo puedes solicitar un tiempo mayor para tareas que consideres que llevan un tiempo extra al evaluado'
      },
      { 
        en: 'Create task templates and reminders to streamline assignments',
        es: 'Crea plantillas de tareas y recordatorios para agilizar el proceso'
      }
    ],
  };

  return (
    <ProductLayout data={data} >
      <Seo title={data.title} />

      <Section color={BG}>
        <Description location={data.title} />
      </Section>
      <Section color={BG_DARK}>
        <SecondSection />
      </Section>
    </ProductLayout >
  );
};
export default Taskme;

const query = graphql`
query taskme {
    allFile(
      filter: {extension: {regex: "/(png)/"}, 
        name: {in: [
          "taskme-hero",
          "taskme-icon",
          "taskme-banner",
          "digitalmenu-hero-mobile",
          "digitalmenu-banner-mobile"
        ]}}
    ) {
      edges {
        node {
          base
          childrenImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }  
`;