import React from 'react'
import Highlight from '../../../components/Highlight';
import { StaticImage } from "gatsby-plugin-image";
import { ThreeColumns, Container } from '../../../GlobalStyles';

import useI18n from '../../../i18n/useI18n';

const SecondSection = () => {

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'Plan current',
        es: 'Planea'
    }    
    const title = {
        en: 'tasks and future event tasks',
        es: 'tareas actuales y del futuro'
    }

    return (
        <Container>
            <ThreeColumns>
                <div className='col-1'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='700'>
                    <StaticImage
                        src="../../../images/taskme-2.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="taskme-2"
                        className="img-container"
                        imgClassName="img-fluid"
                    />
                </div>

                <div className='center'data-sal="slide-up" data-sal-easing="ease-in-circ">
                    <h2><Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}</h2>
                    <p>
                        {translateObject({
                            en: `Is there an event in 2 months? Prepare in advance all the tasks of the staff who will be in charge, 
                            so they will have time to review all the tasks, clarify doubts and that the events always go perfectly.`,
                            es: `¿Hay un evento en 2 meses? Prepara con anticipación todas las tareas del personal que estará a cargo, 
                            así tendrán tiempo para revisar todo, aclarar dudas y que los eventos salgan siempre a la perfección.`
                        })}
                    </p>
                </div>

                <div className='col-3'data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-duration='700'>
                    <StaticImage
                        src="../../../images/taskme-3.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="taskme-3"
                        className='img-container'
                        imgClassName='overflowed-img'
                    />
                </div>
            </ThreeColumns>
        </Container>
    );
}

export default SecondSection;